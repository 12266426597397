const INFO = {
	main: {
		title: "DB Compass by Filippo Maguolo",
		name: "Filippo Maguolo",
		email: "info@dbcompass.net",
		logo: "../logo.png",
	},

	// socials: {
	// 	twitter: "https://twitter.com/",
	// 	github: "https://github.com/",
	// 	linkedin: "https://linkedin.com/",
	// 	instagram: "https://instagram.com/",
	// 	stackoverflow: "https://stackoverflow.com/",
	// 	facebook: "https://facebook.com/",
	// },

	homepage: {
		title: "DB Compass.",
		description:
   		    `Access your database from anywhere.
			Directly access your server without the need of additional setup or intermediate layers.
			With conditional formatting in grid & JavaScript integration.
			`,
	},

	about: {
		title: "I’m Filippo Maguolo....",
		description:
			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima. Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima. Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Multi DB Compass",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "logo1.png",
			linkText: "View Project",
			link: "https://apps.apple.com/it/app/mysql-mobile-client/id1406175528",
		},

		{
			title: "DB Compass for Oracle",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "logo_ora.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "DB Compass for MySQL",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "logo_my.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "DB Compass for PostgreSQL",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "logo_pg.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "DB Compass for SQL Server",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "logo_mssql.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
